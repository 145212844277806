<template>
  <v-card
    :key="componentKey"
    class="rounded-xl"
    flat
  >
    <v-card-title>
      {{ $t('OverView') }}
    </v-card-title>
    <v-card-text>
      <!-- display message if not have data -->
      <div
        v-if="checkIfHaveData.length === 0"
        class="d-flex flex-column align-center justify-center"
        style="height: 298px"
      >
        <v-icon
          color="primary"
          size="40"
        >
          {{ icons.mdiMagnify }}
        </v-icon>
        <p class="mb-2 text-base">
          {{ $t('NotEnoughData') }}
        </p>
        <v-btn
          color="primary"
          class="rounded-lg"
          outlined
          :to="{name: 'apps-user-list'}"
        >
          {{ $t('AddInvitees') }}
        </v-btn>
      </div>
      <div v-else>
        <vue-apex-charts
          type="donut"
          height="325"
          :options="donutChart.chartOptions"
          :series="donutChart.series"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiMagnify } from '@mdi/js'
import { computed, ref, watchEffect } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: ['data'],

  setup(props) {
    const generalDetailsChart = ref()
    const checkIfHaveData = ref()
    const donutChartLabels = ref(computed(() => Object.keys(generalDetailsChart.value || {}).map(el => i18n.t(el))))
    const componentKey = ref(0)

    // filtering out 'pending' or 'inProgress'
    const filterKey = store.state.eventData.progressStatus > 0 ? 'pending' : 'inProgress'

    generalDetailsChart.value = Object.keys(props.data || {})
      .filter(key => key !== 'changedDecision' && key !== filterKey && key !== 'viewCount')
      .reduce((cur, key) => Object.assign(cur, { ...generalDetailsChart.value, [key]: props.data[key] }), {})
    checkIfHaveData.value = Object.values(props.data || {}).filter(el => el !== '0')

    const chartColors = {
      colors: {
        approved: '#a9f5ac',
        declined: '#ff9c9c',
        inProgress: '#bc97fc',
        pending: '#BDBDBD',
      },
    }

    const donutChart = ref({
      series: Object.values(generalDetailsChart.value || {}).map(el => Number(el)),
      chartOptions: {
        // the label of chart
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },

        colors: [
          chartColors.colors.approved,
          chartColors.colors.declined,
          Object.keys(generalDetailsChart.value)[2] === 'inProgress'
            ? chartColors.colors.inProgress
            : chartColors.colors.pending,
        ],

        // the percent on the pie
        dataLabels: {
          enabled: true,
          textAnchor: 'top',
          distributed: false,

          style: {
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
          },
          formatter(val) {
            return `${Math.round(val)}%`
          },
        },

        plotOptions: {
          pie: {
            donut: {
              // the width size of donut
              size: '70%',
            },
          },
        },

        labels: [],
      },
    })

    const currentLocale = ref(i18n.locale)

    // Update the labels whenever i18n.locale changes
    watchEffect(() => {
      donutChart.value.chartOptions.labels = donutChartLabels.value
      if (i18n.locale !== currentLocale.value) {
        componentKey.value += 1
        currentLocale.value = i18n.locale
      }
    })

    return {
      chartColors,
      generalDetailsChart,
      checkIfHaveData,
      donutChart,
      donutChartLabels,
      componentKey,
      currentLocale,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>
<style scoped>
::v-deep .apexcharts-tooltip.apexcharts-theme-dark {
  color: #000000;
}

::v-deep .apexcharts-canvas .apexcharts-pie-label {
  /* font-size: 60px !important; */
  fill: #000000;
  font-weight: 500;
}
::v-deep .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 15px !important;
}
</style>
