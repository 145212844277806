export default function useResponseType() {
  const responseType = {
    INVITATION: 0,
    CALL: 1,
  }

  return {
    responseType,
  }
}
