<template>
  <v-card
    :key="componentKey"
    class="rounded-xl"
    flat
  >
    <v-card-title class="pb-0 text-wrap">
      {{ $t('GroupsClassificationChart') }}
    </v-card-title>
    <v-card-text class="pr-1 pl-1">
      <!-- display message if not have data -->
      <div
        v-if="data.length === 0"
        class="d-flex flex-column align-center justify-center"
        style="height: 298px"
      >
        <v-icon
          color="primary"
          size="40"
        >
          {{ icons.mdiMagnify }}
        </v-icon>
        <p class="mb-2 text-base">
          {{ $t('NotEnoughData') }}
        </p>
        <v-btn
          color="primary"
          class="rounded-lg"
          outlined
          :to="$store.state.groupsData.length > 0 ? {name: 'apps-user-list'} : {name: 'apps-user-manage-group'}"
        >
          {{ $store.state.groupsData.length > 0 ? $t('AssignGroupsToInvitees') : $t('CreateGroups') }}
        </v-btn>
      </div>
      <div v-else>
        <vue-apex-charts
          type="bar"
          height="298"
          :options="barChart.chartOptions"
          :series="barChart.series"
          :width="barChart.width"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiMagnify } from '@mdi/js'
import { computed, ref, watchEffect } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: ['data'],
  setup(props) {
    const componentKey = ref(0)

    const chartColors = {
      colors: {
        approved: '#a9f5ac',
        declined: '#ff9c9c',
        inProgress: '#bc97fc',
        pending: '#BDBDBD',
      },
    }

    // adding null groups for series if groups number is under 5.
    const addNullSeriesGroups = () => {
      if (props.data.length > 5) {
        return []
      }

      return Array.from({ length: 7 - props.data.length }, () => '0')
    }

    // adding null groups for categories if groups number is under 5.
    const addNullCategories = () => {
      if (props.data.length > 5) {
        return []
      }

      return Array.from({ length: 7 - props.data.length }, () => '')
    }
    const barChart = ref({
      series: [
        store.state.eventData.progressStatus > 0
          ? {
              name: 'inProgress',
              data: [...props.data.map(el => el.inProgress), ...addNullSeriesGroups().map(el => `${el}`)],
            }
          : {
              name: 'pending',
              data: [...props.data.map(el => el.pending), ...addNullSeriesGroups().map(el => `${el}`)],
            },

        {
          name: 'approved',
          data: [...props.data.map(el => el.approved), ...addNullSeriesGroups().map(el => `${el}`)],
          fill: {},
        },
        {
          name: 'declined',
          data: [...props.data.map(el => el.declined), ...addNullSeriesGroups().map(el => `${el}`)],
          fill: {},
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },

        tooltip: {
          enabled: true,
        },

        colors: [
          store.state.eventData.progressStatus > 0 ? chartColors.colors.inProgress : chartColors.colors.pending,
          chartColors.colors.approved,
          chartColors.colors.declined,
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            dataLabels: {
              position: 'center',
            },
          },
        },

        // The total number of the group
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '8px',
            fontFamily: 'Helvetica, Arial, sans-serif',
          },
        },

        xaxis: {
          labels: {
            show: true,
            rotate: 40,
          },
          categories: [...props.data.map(el => el.group_name), ...addNullCategories().map(el => `${el}`)],
          axisBorder: {
            show: true,
          },
          rotate: 0,
          offsetX: 0,
          offsetY: 0,
          axisTicks: {
            show: false,
          },
        },

        grid: {
          show: true,

          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },

        yaxis: {
          opposite: false,
          axisTicks: {
            show: false,
          },

          labels: {
            show: false,
          },
        },

        legend: {
          position: 'right',
          offsetX: -20,
          offsetY: 0,
          onItemClick: {
            toggleDataSeries: false,
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: 0,
                offsetY: 0,
              },
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: 40,
                },
              },
              grid: {
                show: true,

                xaxis: {
                  lines: {
                    show: false,
                  },
                },
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              xaxis: {
                labels: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
              },
              yaxis: {
                opposite: false,
                axisBorder: {
                  show: true,
                },
                labels: {
                  show: true,
                  align: 'center',
                  minWidth: 0,
                  maxWidth: 'auto',
                  style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  formatter: value => value,
                },
              },
            },
          },
        ],
      },
    })
    const currentLocale = ref(i18n.locale)

    // Update the labels whenever i18n.locale changes
    const donutChartLabels = ref(
      computed(() => [
        store.state.eventData.progressStatus > 0 ? i18n.t('inProgress') : i18n.t('pending'),
        i18n.t('approved'),
        i18n.t('declined'),
      ]),
    )
    watchEffect(() => {
      barChart.value.series.forEach((item, index) => {
        barChart.value.series[index] = {
          ...item,
          name: donutChartLabels.value[index],
        }
      })

      if (i18n.locale !== currentLocale.value) {
        componentKey.value += 1
        currentLocale.value = i18n.locale
      }
    })

    return {
      chartColors,
      addNullSeriesGroups,
      addNullCategories,
      donutChartLabels,
      componentKey,
      barChart,
      currentLocale,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>
  <style scoped>
::v-deep .apexcharts-datalabels .apexcharts-data-labels {
  text-shadow: 0 0 black;
}
::v-deep .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  /* clip-path: inset(0% -10% -10% -10% round 10px); */
  clip-path: inset(0% 0% 0% 0% round 10px);
}
::v-deep .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 15px !important;
}
</style>
