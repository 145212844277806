<template>
  <div
    style="justify-self: center;"
    dir="rtl"
    class="countDown text-no-wrap flex-wrap"
  >
    <v-card
      color="#9155FD"
      class="justify-center align-center pa-5 mx-auto"
      width="190px"
    >
      <div
        class="text-6xl justify-center content-center white--text"
        dir="ltr"
      >
        <v-row
          align="center"
          justify="center"
        >
          <div
            class="days mx-3"
          >
            {{ displayDays }}
          </div>
          <span class="leading-snug">:</span>
          <div class="hours mx-3">
            {{ displayHours }}
          </div>
          <span class="leading-snug">:</span>
          <div class="minutes mx-3">
            {{ displayMinutes }}
          </div>
        </v-row>
      </div>
    </v-card>
    <v-card
      outlined
      color="transparent"
      class="justify-center align-center mx-auto mt-3"
      width="200px"
    >
      <v-row
        align="center"
        justify="center"
      >
        <div class="label text-sm bottom-0 absolute minutes mx-n2">
          minutes
        </div>

        <div class="label text-sm bottom-0 absolute hours mx-6">
          hours
        </div>
        <div class="label text-sm bottom-0 absolute">
          days
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ['eventDateProps'],
  data: () => ({
    displayDays: 0,
    displayHours: 0,
    displayMinutes: 0,
    displaySeconds: 0,
    intervalId: null,
  }),
  watch: {
    eventDateProps: {
      immediate: true,
      deep: true,
      handler() {
        this.startTimer()
      },
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    formatNum(num) {
      return num < 10 ? `0${num}` : num
    },
    updateDisplayTime() {
      const now = new Date()
      const eventDate = new Date(this.eventDateProps || 0)
      const distance = eventDate.getTime() - now.getTime()

      if (distance < 0) {
        clearInterval(this.intervalId)
        this.intervalId = null
        this.displayDays = '00'
        this.displayHours = '00'
        this.displayMinutes = '00'
        this.displaySeconds = '00'

        return
      }

      const days = Math.floor(distance / 86400000) // 1000 * 60 * 60 * 24
      const hours = Math.floor((distance % 86400000) / 3600000) // 1000 * 60 * 60
      const minutes = Math.floor((distance % 3600000) / 60000) // 1000 * 60
      const seconds = Math.floor((distance % 60000) / 1000) // 1000

      this.displayDays = this.formatNum(days)
      this.displayHours = this.formatNum(hours)
      this.displayMinutes = this.formatNum(minutes)
      this.displaySeconds = this.formatNum(seconds)
    },
    startTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      this.updateDisplayTime()
      this.intervalId = setInterval(this.updateDisplayTime, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
.days,
.hours,
.minutes {
  position: relative;
}
.countDown {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 23px;
}
</style>
