<template>
  <div>
    <v-card
      class="rounded-xl"
      flat
    >
      <div
        class="d-flex flex-sm-row flex-column pb-1"
      >
        <!-- last response -->
        <div
          class="flex-grow-1"
        >
          <v-card-title
            class="pt-3 align-center"
          >
            <span>
              {{ $t('RecentResponse') }}
            </span>
            <v-spacer></v-spacer>
            <v-btn
              class="px-0"
              to="guest/list"
              plain
              style="min-width: 0px; font-size: 12px;"
              :disabled="lastRespondersData.length === 0"
            >
              {{ $t('ViewAll') }}
            </v-btn>
          </v-card-title>
          <!-- Recent response details -->
          <v-card-text class="px-2">
            <!-- No data display -->
            <div
              v-if="lastRespondersData.length === 0"
              class="d-flex flex-column align-center justify-center"
              style="height: 280px"
            >
              <v-icon
                color="primary"
                size="40"
              >
                {{ icons.mdiClockOutline }}
              </v-icon>
              <p class="text-base">
                {{ $t('NoDataYet') }}
              </p>
            </div>

            <div
              v-else
            >
              <v-list style="min-height: 280px">
                <v-list-item
                  v-for="(data, index) in lastRespondersData "
                  :key="index"
                  class="d-flex pb-1"
                  :class="$vuetify.breakpoint.mdAndDown ? 'px-0' : ''"
                >
                  <div class="d-flex align-center flex-grow-1 flex-wrap">
                    <!-- guest details -->
                    <div class="d-flex align-center flex-wrap">
                      <v-avatar
                        class="v-avatar-light-bg"
                        size="35"
                        :color="getStatusOptionStatsField(data.status, 'color')"
                        :class="`${getStatusOptionStatsField(data.status, 'color')}--text`"
                      >
                        <span
                          class="font-weight-medium"
                        >{{ avatarText(data.fullName) }}</span>
                      </v-avatar>

                      <div
                        class="d-flex flex-column ms-0"
                        style="padding: 5px;"
                      >
                        <h4 class="font-weight-semibold">
                          {{ data.fullName }}
                        </h4>
                        <span class="text-xs me-1">
                          {{ moment(data.createdAt).format('DD/MM/YY HH:mm') }}
                        </span>
                      </div>
                    </div>

                    <v-spacer></v-spacer>
                    <!-- guest status and response type -->

                    <div
                      class="d-flex align-center"
                    >
                      <div class="me-3">
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              class="v-avatar-light-bg"
                              size="32"
                              v-bind="attrs"
                              :color="getStatusOptionStatsField(data.status, 'color')"
                              :class="`${getStatusOptionStatsField(data.status, 'color')}--text`"
                              v-on="on"
                            >
                              <v-icon
                                size="22"
                                :color="getStatusOptionStatsField(data.status, 'color')"
                                class="rounded-0"
                              >
                                {{ getStatusOptionStatsField(data.status, 'icon') }}
                              </v-icon>
                            </v-avatar>
                          </template>
                          <span> {{ $t(getStatusOptionStatsField(data.status, 'title')) }}</span>
                        </v-tooltip>
                      </div>

                      <div>
                        <v-icon
                          size="20"
                        >
                          {{ data.responseType === responseType.CALL ? icons.mdiPhone : icons.mdiMessageProcessingOutline }}
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
        </div>

        <v-divider
          class="my-sm-5 mx-0"
          :vertical="$vuetify.breakpoint.smAndUp"
        ></v-divider>
        <!-- change decision -->
        <div
          class="flex-grow-1"
        >
          <v-card-title
            class="pt-3 align-center"
          >
            <span class="me-3">{{ $t('ChangedDecision') }}</span>
            <v-spacer></v-spacer>
            <!-- list of Changed decision -->
            <v-dialog
              v-model="ChangedDecisionDialogVisible"
              max-width="380px"
              scrollable
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="px-0"
                  plain
                  style="min-width: 0px;font-size: 12px;"
                  v-bind="attrs"
                  :disabled="changedDecisionData.length === 0"
                  v-on="on"
                >
                  {{ $t('ViewAll') }}
                </v-btn>
              </template>

              <v-card
                max-height="500px"
                class="rounded-xl"
              >
                <v-card-title>
                  {{ $t('ChangedDecision') }}
                </v-card-title>
                <v-divider></v-divider>
                <perfect-scrollbar
                  :options="perfectScrollbarOptions"
                  class="scrollBarRecentDecision"
                >
                  <v-card-text>
                    <v-list
                      class="overflow-y-auto"
                    >
                      <v-list-item
                        v-for="(item, index) in changedDecisionData "
                        :key="index"
                        class="px-0 mt-0"
                      >
                        <div class="d-flex align-center flex-grow-1 flex-wrap">
                          <div class="d-flex align-center flex-wrap">
                            <v-avatar
                              class="v-avatar-light-bg"
                              size="35"
                            >
                              <span
                                class="font-weight-medium"
                              >{{ avatarText(item.fullName) }}
                              </span>
                            </v-avatar>

                            <div
                              class="d-flex flex-column ms-0"
                              style="padding: 5px"
                            >
                              <h4 class="font-weight-semibold">
                                {{ item.fullName }}
                              </h4>
                              <span class="text-xs">
                                {{ moment(item.createdAt).format('DD/MM/YY HH:mm') }}
                              </span>
                            </div>
                          </div>
                          <v-spacer></v-spacer>

                          <div
                            class="d-flex flex-grow-1 justify-end"
                          >
                            <div>
                              <v-avatar
                                class="v-avatar-light-bg"
                                size="32"
                                :color="getStatusOptionStatsField(item.prevStatus, 'color')"
                                :class="`${getStatusOptionStatsField(item.prevStatus, 'color')}--text`"
                              >
                                <v-icon
                                  size="22"
                                  :color="getStatusOptionStatsField(item.prevStatus, 'color')"
                                  class="rounded-0"
                                >
                                  {{ getStatusOptionStatsField(item.prevStatus, 'icon') }}
                                </v-icon>
                              </v-avatar>
                            </div>

                            <div v-if="$vuetify.rtl">
                              <v-icon
                                size="20"
                              >
                                {{ icons.mdiChevronLeft }}
                              </v-icon>
                            </div>
                            <div v-if="!$vuetify.rtl">
                              <v-icon
                                size="20"
                              >
                                {{ icons.mdiChevronRight }}
                              </v-icon>
                            </div>

                            <div>
                              <v-avatar
                                class="v-avatar-light-bg"
                                size="32"
                                :color="getStatusOptionStatsField(item.status, 'color')"
                                :class="`${getStatusOptionStatsField(item.status, 'color')}--text`"
                              >
                                <v-icon
                                  size="22"
                                  :color="getStatusOptionStatsField(item.status, 'color')"
                                  class="rounded-0"
                                >
                                  {{ getStatusOptionStatsField(item.status, 'icon') }}
                                </v-icon>
                              </v-avatar>
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </perfect-scrollbar>

                <v-divider></v-divider>

                <v-card-actions class="align-self-center">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    rounded
                    @click="ChangedDecisionDialogVisible = false"
                  >
                    {{ $t('Close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>

          <v-card-text class="px-2">
            <!-- No data display -->
            <div
              v-if="changedDecisionData.length === 0"
              class="d-flex flex-column align-center justify-center"
              style="height: 280px"
            >
              <v-icon
                color="primary"
                size="40"
              >
                {{ icons.mdiClockOutline }}
              </v-icon>
              <p class="text-base">
                {{ $t('NoDataYet') }}
              </p>
            </div>
            <!-- Display 5 last changed decision  -->
            <div
              v-else
            >
              <v-list style="min-height: 280px">
                <v-list-item
                  v-for="(item, index) in changedDecisionData.slice(0,5)"
                  :key="index"
                  class="d-flex pb-1"
                  :class="$vuetify.breakpoint.mdAndDown ? 'px-0' : ''"
                >
                  <div class="d-flex align-center flex-grow-1 flex-wrap">
                    <div class="d-flex align-center flex-wrap">
                      <!-- Guest details -->
                      <v-avatar
                        class="v-avatar-light-bg"
                        size="35"
                      >
                        <span
                          class="font-weight-medium"
                        >{{ avatarText(item.fullName) }}
                        </span>
                      </v-avatar>

                      <div
                        class="d-flex flex-column ms-0"
                        style="padding: 5px"
                      >
                        <h4 class="font-weight-semibold">
                          {{ item.fullName }}
                        </h4>
                        <span class="text-xs me-1">
                          {{ moment(item.createdAt).format('DD/MM/YY HH:mm') }}
                        </span>
                      </div>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- The changed status -->
                    <div
                      class="d-flex align-center justify-end"
                    >
                      <div>
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              class="v-avatar-light-bg"
                              size="32"
                              v-bind="attrs"
                              :color="getStatusOptionStatsField(item.prevStatus, 'color')"
                              :class="`${getStatusOptionStatsField(item.prevStatus, 'color')}--text`"
                              v-on="on"
                            >
                              <v-icon
                                size="22"
                                :color="getStatusOptionStatsField(item.prevStatus, 'color')"
                                class="rounded-0"
                              >
                                {{ getStatusOptionStatsField(item.prevStatus, 'icon') }}
                              </v-icon>
                            </v-avatar>
                          </template>
                          <span>  {{ $t(getStatusOptionStatsField(item.prevStatus, 'title')) }} </span>
                        </v-tooltip>
                      </div>

                      <div v-if="$vuetify.rtl">
                        <v-icon
                          size="20"
                        >
                          {{ icons.mdiChevronLeft }}
                        </v-icon>
                      </div>
                      <div v-if="!$vuetify.rtl">
                        <v-icon
                          size="20"
                        >
                          {{ icons.mdiChevronRight }}
                        </v-icon>
                      </div>

                      <div>
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              class="v-avatar-light-bg"
                              size="32"
                              :color="getStatusOptionStatsField(item.status, 'color')"
                              :class="`${getStatusOptionStatsField(item.status, 'color')}--text`"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                size="22"
                                :color="getStatusOptionStatsField(item.status, 'color')"
                                class="rounded-0"
                              >
                                {{ getStatusOptionStatsField(item.status, 'icon') }}
                              </v-icon>
                            </v-avatar>
                          </template>
                          <span>  {{ $t(getStatusOptionStatsField(item.status, 'title')) }} </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import useGuestStatuses from '@/composables/useGuestStatuses'
import useResponseType from '@/composables/useResponseType'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiChevronRight, mdiClockOutline, mdiMessageProcessingOutline, mdiPhone } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

export default {
  components: {
    PerfectScrollbar,
  },
  props: ['lastRespondersData', 'changedDecisionData'],
  setup() {
    const ChangedDecisionDialogVisible = ref(false)
    const { statusOptions } = useGuestStatuses()
    const { responseType } = useResponseType()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const getStatusOptionStatsField = (key, field) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      statusOptions.filter(el => el.value === key).map(el => el[field])[0]

    return {
      avatarText,
      moment,
      statusOptions,
      ChangedDecisionDialogVisible,
      perfectScrollbarOptions,
      responseType,
      getStatusOptionStatsField,

      icons: {
        mdiPhone,
        mdiMessageProcessingOutline,
        mdiChevronLeft,
        mdiChevronRight,
        mdiClockOutline,
      },
    }
  },
}
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
.scrollBarRecentDecision {
  max-height: calc(var(--vh, 1vh) * 80);
}
</style>
