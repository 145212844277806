<template>
  <v-card
    class="rounded-xl"
    flat
  >
    <v-card-title
      class="pb-0 text-wrap"
      style="word-break: break-word"
    >
      {{ $t('attendingByGroups') }}
    </v-card-title>
    <v-card-text>
      <!-- display message if not have data -->
      <div
        v-if="Object.keys(approvedDetailsChart).length === 0"
        class="d-flex flex-column align-center justify-center"
        style="height: 298px"
      >
        <v-icon
          color="primary"
          size="40"
        >
          {{ icons.mdiClockOutline }}
        </v-icon>
        <p class="text-base">
        {{ $t('NoDataYet') }}
        </p>
      </div>
      <div v-else>
        <vue-apex-charts
          type="bar"
          height="298"
          :options="barChartArrive.chartOptions"
          :series="barChartArrive.series"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMagnify, mdiClockOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: ['data'],
  setup(props) {
    const approvedDetailsChart = ref()

    approvedDetailsChart.value = props.data
      .filter(el => el.approved !== '0')
      .reduce((cur, key) => Object.assign(cur, { ...approvedDetailsChart.value, [key.group_name]: key.approved }), {})

    const sortedObject =
      Object.keys(approvedDetailsChart.value).length > 0
        ? Object.fromEntries(
            Object.entries(approvedDetailsChart.value).sort(([, value1], [, value2]) => value2 - value1),
          )
        : []

    // adding null groups for series if groups number is under 5.
    const addNullSeriesGroups = () => {
      const groupsLength = props.data.filter(el => Number(el.approved) > 0).length

      if (groupsLength > 5) {
        return []
      }

      return Array.from({ length: 7 - groupsLength }, () => '0')
    }

    // adding null groups for categories if groups number is under 5.
    const addNullCategories = () => {
      const categoriesLength = props.data.filter(el => Number(el.approved) > 0).length

      if (categoriesLength > 5) {
        return []
      }

      return Array.from({ length: 7 - categoriesLength }, () => '')
    }

    const barChartArrive = {
      series: [
        {
          data: [...Object.values(sortedObject || {}), ...addNullSeriesGroups().map(el => `${el}`)],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },

        tooltip: {
          enabled: false,
        },

        colors: ['#a9f5ac', '#ff0000'],

        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
            barHeight: 35,
          },
        },

        // The total number of the group
        dataLabels: {
          enabled: true,

          offsetX: 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
          },
          formatter: value => value,
        },

        xaxis: {
          opposite: true,
          labels: {
            show: false,
          },

          categories: [...Object.keys(sortedObject || {}), ...addNullCategories().map(el => `${el}`)],

          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          opposite: false,

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          labels: {
            show: true,
            align: 'center',
            minWidth: 0,
            maxWidth: 'auto',
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: value => value,
          },
        },

        grid: {
          show: true,

          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
    }

    return {
      approvedDetailsChart,
      addNullSeriesGroups,
      addNullCategories,
      barChartArrive,

      icons: {
        mdiMagnify,
        mdiClockOutline,
      },
    }
  },
}
</script>
<style scoped>
::v-deep .apexcharts-datalabels .apexcharts-data-labels {
  text-shadow: 0 0 black;
}
::v-deep .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0% 0% 0% -11% round 20px);
}
</style>
